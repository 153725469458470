import React from 'react'
import './DeliveryOverview.scss'
import { useTranslation } from "react-i18next";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

import PropertyField from '../components/PropertyField/PropertyField';
import BaleCard from '../components/BaleCard/BaleCard';
import ImageCard from '../components/ImageCard/ImageCard';
import MaterialDropdown from '../components/MaterialDropdown/MaterialDropdown';
import WeighingSlip from '../components/WeighingSlip/WeighingSlip';
import Reclamation from '../components/Reclamation/Reclamation';

// Importing icons
import back_icon        from '../assets/icons/white/back_small.png';
import supplier_icon    from '../assets/icons/white/delivery.png';
import date_icon        from '../assets/icons/white/calendar.png';
import document_icon    from '../assets/icons/white/document.png';
import user_icon        from '../assets/icons/white/user.png';
import label_icon       from '../assets/icons/white/label.png';
import location_icon    from '../assets/icons/white/location.png';
import bales_icon       from '../assets/icons/white/bales_stacked.png';
import shapes_icon      from '../assets/icons/white/shape.png';
import piechart_icon    from '../assets/icons/white/piechart.png';
import color_icon       from '../assets/icons/white/color.png';

const iconMapping = {
    "notes": document_icon,
    "user": user_icon,
    "label": label_icon,
    "location": location_icon,
    "bales": bales_icon,
    "shapes": shapes_icon,
    "piechart": piechart_icon,
    "color": color_icon,
}

export default function Delivery({ handleDeliveryFieldChange, deleteDelivery, deliveryId, delivery, showDeliveryImage, showBale }) {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const { deliverySettings } = React.useContext(AuthContext);
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);
    const [showWeighingSlipPopup, setShowWeighingSlipPopup] = React.useState(false);
    const [showReclamationPopup, setShowReclamationPopup] = React.useState(false);

    async function returnToDeliveriesOverview() {
        // Navigate back to deliveries page
        navigate(-1);
    }
 
    /* Show the delivery-overview page */
    return (
        <div className="delivery">
            <div className="delivery--header">
                <div onClick={returnToDeliveriesOverview} className="delivery--back">
                    <img src={back_icon} alt="Back" className="delivery--back-icon"/>
                </div>
                <h3 className="delivery--title">{t("delivery")} #{deliveryId}</h3>
                <div className="delivery--header-buttons">
                    {deliverySettings?.features?.reclamations && <button onClick={() => setShowReclamationPopup(true)} className="delivery--reclamation-button">{t("reclamation")}</button>}
                    <button onClick={returnToDeliveriesOverview} className="delivery--save">{t("general_save")}</button>
                    <button onClick={() => setShowDeletePopup(true)} className="delivery--delete">{t("general_delete")}</button>
                </div>
            </div>

            <div className="delivery--content">
                <div className="delivery--properties">
                    <MaterialDropdown
                        name = "materials"
                        value = {delivery.base_labels.materials}
                        handleChange={handleDeliveryFieldChange}
                        splitProductGroup={true}
                    />
                    <PropertyField 
                        icon={supplier_icon} 
                        title={t("property_supplier")}
                        value={delivery.supplier} 
                        name={"supplier"} 
                        type="select-extendible"
                        handleChange={handleDeliveryFieldChange} 
                        editable={true}
                    />
                    <PropertyField 
                        icon={document_icon} 
                        title={t("property_weighing_slip")}
                        value={delivery?.weighingSlipData?.weighing_slip_nr} 
                        name={"weighing_slip_nr"} 
                        type={null}
                        handleChange={handleDeliveryFieldChange} 
                        editable={false}
                        onClick={() => {setShowWeighingSlipPopup(true)}}
                    />
                    <PropertyField 
                        icon={location_icon} 
                        title={t("property_storage_field")}
                        value={delivery.storage_field} 
                        name={"storage_field"} 
                        type="select-extendible"
                        handleChange={handleDeliveryFieldChange} 
                        editable={true}
                    />                
                    <PropertyField 
                        icon={document_icon} 
                        title={t("property_notes")}
                        value={ delivery.notes } 
                        name="notes"
                        type="textarea"
                        handleChange={handleDeliveryFieldChange}
                        editable={true}
                        grid_column="2 / span 2"
                        grid_row="2 / span 2"
                    />    
                    {Object.entries(deliverySettings?.custom_delivery_fields ?? {}).map(([key, value]) => {
                        return (
                            <PropertyField 
                                icon={iconMapping[value.icon]} 
                                title={value.title} 
                                value={delivery?.[key]} 
                                name={key} 
                                type={value.type}
                                handleChange={handleDeliveryFieldChange} 
                                editable={true}
                            />
                        )
                    })}
                    <PropertyField 
                        icon={date_icon} 
                        title={t("property_date")}
                        value={delivery.date} 
                        name="date"
                        type="datetime-local"
                        handleChange={handleDeliveryFieldChange}
                        editable={true}
                    />
                    <PropertyField 
                        icon={user_icon} 
                        title={t("property_submitted_by")}
                        value={delivery.delivery_submitted_by_user} 
                        name={"delivery_submitted_by_user"} 
                        type="select-extendible"
                        handleChange={handleDeliveryFieldChange}
                        editable={true}
                    />
                </div>

                <div className="delivery--bales-header">
                    <h3 className="delivery--bales-title">{t("delivery_images_delivery")}</h3>
                </div>
                <div className="delivery--delivery-images">
                    <div className="delivery--delivery-images-card-wrapper">
                        <div className="delivery--delivery-images-card-new" onClick={() => navigate(`/delivery`, {state: {delivery_id: deliveryId, mode: "scanning"}})}>
                            <p>+</p>
                        </div>
                        {Object.entries(delivery.delivery_images || {}).sort().map(([deliveryImageId, deliveryImage]) => {
                            return (
                                <div onClick={() => {showDeliveryImage(deliveryImageId)}} key={deliveryImageId}>
                                    <ImageCard key={deliveryImageId} 
                                        image_URL={deliveryImage.image_URL} 
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="delivery--bales-header">
                    <h3 className="delivery--bales-title">{t("delivery_images_bales")}</h3>
                    <p className="delivery--bales-count">{Object.keys(delivery.bales || {}).length} {t("delivery_bales_detected")}</p>
                </div>
                <div className="delivery--bales">
                    <div className="delivery--bales-card-wrapper">
                        {Object.entries(delivery.bales || {})
                            .sort((a, b) => {
                                return a[1]?.flagged && !b[1]?.flagged ? -1 : 
                                !a[1]?.flagged && b[1]?.flagged ? 1 : a[0].localeCompare(b[0]);
                            })
                            .map(([baleId, bale]) => {
                            return (
                                <div onClick={() => {showBale(baleId)}} key={baleId}>
                                    <BaleCard key={baleId} 
                                        image_URL={bale.image_URL}
                                        flagged={bale.flagged}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* Delete Delivery Popup Modal */}
            <Modal 
                show={showDeletePopup}
                onHide={() => setShowDeletePopup(false)}
                backdrop = "static"
                className = "delete-modal"
            >
                <div className="modal-box">
                    <Modal.Header>
                        <Modal.Title>{t("delivery_confirm_delete")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button className="modal-button" variant="secondary" onClick={() => setShowDeletePopup(false)}>
                            {t("general_cancel")}
                        </Button>
                        <Button className="modal-button" variant="danger" onClick={deleteDelivery}>
                            {t("general_delete")}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>

            {/* Weighing Slip Popup Modal */}
            <WeighingSlip
                show={showWeighingSlipPopup}
                onHide={() => setShowWeighingSlipPopup(false)}
                weighingSlipData={delivery?.weighingSlipData}
                deliveryId={deliveryId}
                handleWeighingSlipFieldChange={(e) => {
                    handleDeliveryFieldChange({
                        ...e, 
                        target:{"name":"weighingSlipData", "value":{[e.target.name]:e.target.value}}
                    })
                }}
            />

            {/* Reclamation Popup Modal */}
            <Reclamation
                delivery={delivery}
                show={showReclamationPopup}
                onHide={() => setShowReclamationPopup(false)}
                handleReclamationFieldChange={(e) => {
                    handleDeliveryFieldChange({
                        ...e, 
                        target:{"name":"reclamation_data", "value":{[e.target.name]:e.target.value}}
                    })
                }}
                showBale={showBale}
            />
        </div> 
    )
}