import React from 'react'
import './PropertyField.scss'
import { useTranslation } from "react-i18next";

import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { onSnapshot, setDoc, doc } from "firebase/firestore";
import { firestore } from "../../context/firebase";
import { format } from 'date-fns';
import { debounce } from 'lodash';

import MultiSelectDropdown from '../MultiSelectDropdown/MultiSelectDropdown';

export default function PropertyField(props) {
    const { i18n, t } = useTranslation();
    const { currentUser } = React.useContext(AuthContext);
    const [ value, setValue ] = React.useState(props.value);
    const [showAddOptionModal, setShowAddOptionModal] = React.useState(false);
    const [options, setOptions] = React.useState({});
    const inputRef = React.useRef(null);
    const newOptionRef = React.useRef(null);

    // Update value when props change
    React.useEffect(() => {
        setValue(props.value ?? "");
    }, [props.value]);

    function datetimeToString(datetime, seconds=false) {
        /** Convert a datetime object to string */
        if (seconds) {
            return format(datetime, "yyyy-MM-dd'T'HH:mm:ss")
        } else {
            return format(datetime, "yyyy-MM-dd'T'HH:mm")
        }
    }

    // Load the options for the select-extendible field
    React.useEffect(() => {
        if (props.type === "select-extendible") {
            const unsubscribe = onSnapshot(doc(firestore, "clients", currentUser.company.company, "client_settings", "deliveries"), (doc) => {
                const newOptions = doc.data()?.[props.name] ?? {};
                // Check if the current value is not in the options list. If not, add it.
                if (value && !Object.keys(newOptions).includes(value)) {
                    newOptions[value] = value;
                }
                setOptions(newOptions);
            });

            return unsubscribe;
        }
    }, []);

    function handleClick() {
        /* Focus on the input element when clicking on any place on the field */
        if (props.editable && inputRef.current && !showAddOptionModal) {
            inputRef.current.focus();
        }
        if (props.onClick){
            props.onClick();
        }
    }

    function handleChangesWithDebouncing(event) {
        /* 
        Handle changes in input fields with debouncing.
        Update state immediately, but only trigger the update function (for firebase) after 1 second.
        */
        setValue(event.target.value);
        uploadChanges(event);
    }
    
    const uploadChanges = React.useCallback(debounce((event) => {
        // Update value with a debouncing timer.
        props.handleChange(event);
    }, 700), [props.handleChange]);

    function renderView() {
        return (
            <p className="property--value">{value}</p>
        )
    }

    function renderInput() {
        return (
            <input className="property--value"
                type={props.type ? props.type : "text"}
                onChange={handleChangesWithDebouncing}
                name={props.name}
                value={value}
                ref={inputRef}
            /> 
        )
    }

    function renderDatetimeLocal() {
        return (
            <input className="property--value"
                type="datetime-local"
                onChange={(e) => {handleChangesWithDebouncing({...e, target:{"name":props.name, "value":new Date(e.target.value)}})}}
                name={props.name}
                value={value ? datetimeToString(value) : ""}
                ref={inputRef}
            /> 
        )
    }

    function renderTextArea() {
        return (
            <textarea className="property--value property--value-textarea"
                onChange={handleChangesWithDebouncing}
                name={props.name}
                value={value}
                ref={inputRef}
            /> 
        )
    }

    function renderSelect() {
        if (Object.keys(props.options).length > 1) {
            return (
                <select className="property--value"
                    onChange={handleChangesWithDebouncing}
                    name={props.name}
                    value={value}
                    ref={inputRef}
                >
                    <option value="" disabled></option>
                    {Object.entries(props.options)
                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}))
                        .map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        )
                    )}
                </select>
            )
        } else {
            return (
                <p className="property--value">{props.options[value]}</p>
            )
        }
    }

    function renderMultipleChoiceSelect() {
        return (
            <MultiSelectDropdown 
                selectedOptions={value}
                options={props.options}
                onChange={(e) => {handleChangesWithDebouncing({"target":{"name":props.name, "value":e}})}}
            />
        )
    }

    function renderSelectExtendible() {
        /* 
        Render a select field with an additional button to add new options.
        Options are stored in the user document in the Firestore database.
        */
        function addOption() {
            /* Add a new option to the select field. Check that it is valid first. Update on firestore */
            if (!newOptionRef.current.value || newOptionRef.current.value.length === 0) {
                setShowAddOptionModal(false);
                return;
            }
            const newOptionKey = newOptionRef.current.value.toLowerCase().replace(/ /g, "_");
            setDoc(doc(firestore, "clients", currentUser.company.company, "client_settings", "deliveries"), {
                [props.name]: {
                    [newOptionKey]: newOptionRef.current.value,
                }
            }, { merge: true });
            handleChangesWithDebouncing({"target":{"name":props.name, "value":newOptionKey}});
            setShowAddOptionModal(false);
        }

        return(
            <div className="porperty--select-extendible">
                <select className="property--value"
                    onChange={handleChangesWithDebouncing}
                    name={props.name}
                    value={value}
                    ref={inputRef}
                >
                    <option key={0} value={null}></option>
                    {Object.entries(options)
                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB, undefined, {numeric: true}))
                        .map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        )
                    )}
                </select>
                <button className="property--extend-button" onClick={() => setShowAddOptionModal(true)}>+</button>

                <Modal 
                    show={showAddOptionModal}
                    onHide={() => setShowAddOptionModal(false)}
                    backdrop = "static"
                    className = "add-option-modal"
                >
                    <div className="modal-box">
                        <Modal.Header>
                            <Modal.Title>{t("property_field_extendable_new")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <input type={"text"} ref={newOptionRef} /> 
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="modal-button" variant="secondary" onClick={() => setShowAddOptionModal(false)}>
                                {t("general_cancel")}
                            </Button>
                            <Button className="modal-button" variant="danger" onClick={addOption}>
                                {t("general_add")}
                            </Button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        )
    }

    function renderValueField() {
        switch (props.type) {
            case "textarea":
                return renderTextArea();
            case "text":
                return renderInput();
            case "number":
                return renderInput();
            case "date":
                return renderInput();
            case "datetime-local":
                return renderDatetimeLocal();
            case "select":
                return renderSelect();
            case "multiple-choice-select":
                return renderMultipleChoiceSelect();
            case "select-extendible":
                return renderSelectExtendible();
            default:
                return renderView();
        }
    }

    return (
        <div className={props.highlight ? "property-field property--highlight" : "property-field"}
            style={{
                "gridColumn": props.grid_column ? props.grid_column : "span 1",
                "gridRow": props.grid_row ? props.grid_row : "span 1",
            }}
            onClick={handleClick}
        >
            <img className="property--icon" src={props.icon} />
            <p className="property--title">{props.title}</p>
            { props.editable ? renderValueField() : <p className="property--value">{value}</p> }
        </div>
    )
}